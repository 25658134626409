
import { defineComponent, PropType } from "@vue/runtime-core";
import HeaderComponent from "@/components/Header.vue";

import axios from "axios";

interface routesAccess {
  "": Array<string>;
  teasers: Array<string>;
  news: Array<string>;
  offers: Array<string>;
  advertisers: Array<string>;
  "operative-report": Array<string>;
  "finance-report": Array<string>;
  faq: Array<string>;
  affiliates: Array<string>;
  users: Array<string>;
  exchange: Array<string>;
  payouts: Array<string>;
  profile: Array<string>;
  notifications: Array<string>;
}

type T = keyof routesAccess;

export default defineComponent({
  props: {
    name: String as PropType<string>,
    icon: String as PropType<string>,
    htmlClass: {
      type: String as PropType<string | undefined>,
      required: false,
    },
  },
  components: {
    HeaderComponent,
  },
  async created() {
    try {
      const { data } = await axios.get("/api/profile");
      const path = this.$route.path;
      this.isAuth = data ? true : false;
      this.role = data.role;
      if (this.checkIfRoleHasAccessAndRedirect(data.role, path.slice(1))) {
        this.$router.push(path);
      } else {
        this.$router.push(`/`);
      }
    } catch (e) {
      location.href = process.env.VUE_APP_NEW_ADMIN_SITE_ENTRANCE as string;
    }
  },
  data() {
    return {
      isMenuOpened: false,
      isAuth: false,
      role: null,
      isRouteVisible: true,
      routesAccess: {
        "": ["SuperAdmin", "AdminPlus", "AdminGeneral", "AdminContent"],
        teasers: ["SuperAdmin", "AdminPlus", "AdminContent"],
        "teasers-settings": ["SuperAdmin"],
        news: ["SuperAdmin", "AdminPlus", "AdminGeneral", "AdminContent"],
        offers: ["SuperAdmin", "AdminPlus", "AdminGeneral"],
        advertisers: ["SuperAdmin", "AdminPlus", "AdminGeneral"],
        "operative-report": ["SuperAdmin", "AdminPlus", "AdminGeneral"],
        "finance-report": ["SuperAdmin"],
        "push": ["SuperAdmin"],
        "page-settings": ["SuperAdmin"],
        faq: ["SuperAdmin", "AdminPlus", "AdminGeneral", "AdminContent"],
        affiliates: ["SuperAdmin", "AdminPlus", "AdminGeneral"],
        profile: ["SuperAdmin", "AdminPlus", "AdminGeneral", "AdminContent"],
        users: ["SuperAdmin"],
        exchange: ["SuperAdmin"],
        payouts: ["SuperAdmin"],
        notifications: ["SuperAdmin"],
        "log-in": ["SuperAdmin", "AdminPlus", "AdminGeneral", "AdminContent"],
      },
    };
  },
  watch: {
    isMenuOpened() {
      document.querySelector("html")?.classList.toggle("sidebar-opened");
    },
  },
  methods: {
    checkIfRoleHasAccessAndRedirect(role: string, route: string): any {
      return this.routesAccess[route as keyof routesAccess].includes(role);
    },
  },
  mounted() {
    const html = document.querySelector("html");
    const links = document.querySelectorAll(".sidebar__link");

    if (window.innerWidth >= 1280) {
      html?.classList.add("sidebar-opened");
    }

    window.addEventListener("resize", function () {
      if (window.innerWidth >= 1280) {
        html?.classList.add("sidebar-opened");
      } else {
        html?.classList.remove("sidebar-opened");
      }
    });

    links.forEach((link) => {
      if (window.innerWidth < 1280) {
        link.addEventListener("click", () =>
          html?.classList.remove("sidebar-opened")
        );
      }
    });
  },
});
