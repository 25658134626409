<template>
  <div class="header">
    <Logo />
    <div
      :class="[
        'header__bell',
        { _active: pendingPayouts && pendingPayouts.length },
      ]"
    >
      <img src="img/bell-icon.svg" alt="" />
      <div
        class="new-notifications-indicator"
        v-if="pendingPayouts && pendingPayouts.length"
      ></div>
      <div
        class="header__notifications"
        v-if="pendingPayouts && pendingPayouts.length"
      >
        <div @click="$router.push('/payouts')">
          <img src="img/warning-icon.svg" alt="" />
          Новые запросы на выплаты
        </div>
      </div>
    </div>
    <div class="header__profile">
      <div class="profile__image">
        <img
          :src="user ? user.photo : defaultImg"
          alt="profile photo"
          class="header__profile--photo"
        />
      </div>
      <span class="header__profile--name">
        {{ user ? user.name : "admin_name" }}
      </span>
      <i class="icon icon-angle-down"></i>
      <div class="header__profile--actions">
        <router-link to="/profile" class="header__link"
          >Посмотреть профиль</router-link
        >
        <button @click.prevent="logOut">Выйти</button>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo.vue";
import { mapState } from "vuex";

import axios from "axios";

export default {
  name: "HeaderComponent",
  data() {
    return {
      defaultImg: require("@/assets/default-user-image.png"),
      pendingPayouts: [],
    };
  },
  components: {
    Logo,
  },
  computed: {
    ...mapState(["user"]),
  },
  async created() {
    try {
      const { data } = await axios.get("/api/profile");
      this.$store.commit("setUser", data);
    } catch (e) {
      location.href = process.env.VUE_APP_NEW_ADMIN_SITE_ENTRANCE;
    }
    this.loadPayouts();
  },
  methods: {
    async logOut() {
      try {
        await axios.post("/api/log-out");
        this.$store.commit("setUser", null);
        location.href = process.env.VUE_APP_NEW_ADMIN_SITE_ENTRANCE;
      } catch (error) {
        const status = (error.response && error.response.status) || 500;
        this.$router.push(`/error/${status}`);
      }
    },
    async loadPayouts() {
      try {
        const { data } = await axios.get("/api/payouts/get-pending");
        this.pendingPayouts = data;
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }
    },
  },
};
</script>

<style lang="scss">
@import "src/styles/vars";

.header {
  background: #fff;
  padding: 16px 30px;
  display: flex;
  align-items: center;
  .new-notifications-indicator {
    background: $color-blue-light;
    border: 3px solid $color-white;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
  }
  &__bell {
    margin-left: auto;
    flex: 0 0 48px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    transition: background-color 0.2s ease;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      .header__notifications {
        opacity: 1;
        pointer-events: all;
      }
    }
    &:hover,
    &._active {
      background-color: #dfdfff;
    }
    .new-notifications-indicator {
      background-color: red;
    }
  }
  &__notifications {
    position: absolute;
    right: 0;
    top: calc(100% - 10px);
    box-shadow: 0 35px 80px rgba(133, 133, 175, 0.6);
    border-radius: 4px;
    width: 250px;
    z-index: 99;
    background-color: #fff;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease;
    > * {
      color: $color-base;
      transition: background-color 0.3s ease;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      padding: 10px;
      width: 100%;
      display: flex;
      img {
        margin-right: 10px;
      }
      &:hover {
        color: $color-blue-light;
        background: #dfdfff;
      }
    }
  }
  &__profile {
    padding: 15px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #5378fb;
    margin-left: 25px;
    &:hover {
      .header__profile--actions {
        opacity: 1;
        pointer-events: all;
      }
    }
    .icon {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    @media screen and (max-width: 1279px) {
      font-size: 14px;
      line-height: 19px;
    }
    @media screen and (min-width: 1280px) {
      font-size: 16px;
      line-height: 32px;
    }
    &--name {
      margin-left: 12px;
      transition: color 0.2s ease;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .profile__image {
      flex: 0 0 44px;
      max-width: 44px;
      height: 44px;
      overflow: hidden;
      border-radius: 50%;
    }
    &--photo {
      max-width: 100%;
      width: 100%;
    }
    &--info {
      background-color: $color-link;
      color: $color-white;
      padding: 8px;
      box-shadow: 0 10px 30px rgba(133, 133, 175, 0.45);
      border-radius: 4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      position: absolute;
      right: 0;
      top: calc(100% - 10px);
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      .triangle-origin {
        position: absolute;
        right: 20px;
        bottom: 100%;
      }
    }
    &--actions {
      position: absolute;
      right: 0;
      top: 100%;
      box-shadow: 0 35px 80px rgba(133, 133, 175, 0.6);
      border-radius: 4px;
      width: 250px;
      z-index: 99;
      background-color: #fff;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.5s ease;
      button,
      .header__link {
        color: $color-base;
        transition: background-color 0.3s ease;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        display: block;
        padding: 10px;
        width: 100%;
        &:hover {
          color: $color-blue-light;
          background: #dfdfff;
        }
      }
    }
  }
  &:hover {
    .header__profile--name {
      color: $color-blue-light;
    }
  }
  &.is-hover {
    .header__profile--info {
      opacity: 1;
    }
  }
  &._opened {
    .header__profile--photo {
      border-color: $color-link;
    }
    .account__settings {
      display: block !important;
    }
  }
}
</style>
